// 导入axios库
import axios from 'axios';

// 创建axios实例
const axiosInstance = axios.create({
  baseURL: '', 
  timeout: '', 
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    // 在请求发送之前可以进行一些处理，例如添加token等
    const token = localStorage.getItem('tokenInfo');
    if(token) config.headers['Blade-Auth']= `bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    // 在响应数据之前可以进行一些处理
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
