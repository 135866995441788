<template>
	<div class="box">
		<main class="main">
			<div class="go_home" @click="goHome">
				<i class="el-icon-arrow-left"></i>
				<span>返回首页</span>
			</div>

			<div class="right" v-if="type == '/register'">
				<div class="title">
					注册
				</div>
				<div class="login_box">
					<el-form class="form" label-position="top" :model="form" :rules="rules" ref="form"
						v-loading="loading">
						<el-form-item required prop="phone">
							<el-input v-model="form.phone" placeholder="请输入手机号">
								<i slot="prefix" class="el-icon-mobile-phone"></i>
							</el-input>
						</el-form-item>
						<el-form-item required prop="email">
							<el-input v-model="form.email" placeholder="请输入邮箱">
								<i slot="prefix" class="el-icon-message"></i>
							</el-input>
						</el-form-item>
						<el-form-item required prop="password">
							<el-input placeholder="请输入密码" v-model="form.password" show-password>
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
						<el-form-item required prop="rePassword">
							<el-input placeholder="请再次输入密码" v-model="form.rePassword" show-password>
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
						<el-form-item required prop="code">
							<el-input v-model="form.code" placeholder="请输入验证码">
								<i slot="prefix" class="el-icon-key"></i>
								<template #suffix>
									<el-button type="text" @click="getCode(form, 'register')"
										:disabled="getCodeDisabled">
										{{ getCodeText }}
									</el-button>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="toRegister('form')" style="width: 100%">注册</el-button>
							<div @click="login" class="go_login">
								<span>
									已有账号? 立即登录
								</span>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="right" v-if="type == '/forget'">
				<div class="title">
					忘记密码
				</div>
				<div class="login_box">
					<el-form class="form" label-position="top" :model="form" :rules="rules" ref="form"
						v-loading="loading">
						<el-form-item required prop="phone">
							<el-input v-model="form.phone" placeholder="请输入手机号">
								<i slot="prefix" class="el-icon-mobile-phone"></i>
							</el-input>
						</el-form-item>
						<el-form-item required prop="password">
							<el-input placeholder="请输入修改后的密码" v-model="form.password" show-password>
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
						<el-form-item required prop="rePassword">
							<el-input placeholder="请再次输入密码" v-model="form.rePassword" show-password>
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
						<el-form-item required prop="code">
							<el-input v-model="form.code" placeholder="请输入验证码">
								<i slot="prefix" class="el-icon-key"></i>
								<template #suffix>
									<el-button type="text" @click="getCode(form, 'reset')" :disabled="getCodeDisabled">
										{{ getCodeText }}
									</el-button>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item>

							<el-button type="primary" @click="getResetPassword('form')"
								style="width: 100%">找回密码</el-button>

							<div class="login_bottom">
								<div @click="register" class="login_bottom_btn_">
									<span>
										注册账号
									</span>
								</div>
								<div @click="login" class="login_bottom_btn_ register2">
									<span>
										立即登录
									</span>
								</div>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="right" v-if="type == '/login'">
				<to-login @type="getType"></to-login>
			</div>
		</main>

		<Verify @success="success" :mode="'pop'" :captchaType="'blockPuzzle'"
			:imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
	</div>
</template>

<script>
import {
	login,
	getVerificationCode,
	reset,
	getLoginCode,
	getReset,
	getLogin,
	getRegisterCode,
	getRegister1,
	getForget
} from '@/api/login/login.js'
import toLogin from './components/toLogin.vue'
import Verify from "@/components/verifition/Verify.vue"

const TIME_COUNT = 120;
export default {
	components: {
		toLogin,
		Verify
	},
	name: 'login',
	data() {
		//校验手机号和邮箱
		var userName2 = (rule, value, callback) => {
			const phoneReg = /^1(3[0-9]|4[579]|5[0-35-9]|6[56]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
			if (!value) {
				return callback(new Error('请输入手机号'))
			}
			setTimeout(() => {
				if (phoneReg.test(value)) {
					callback()
				} else {
					callback(new Error('请输入正确的手机号'))
				}
			}, 100)
		};
		//校验手机号和邮箱
		var email2 = (rule, value, callback) => {
			const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
			if (!value) {
				return callback(new Error('请输入邮箱'))
			}
			setTimeout(() => {
				if (emailReg.test(value)) {
					callback()
				} else {
					callback(new Error('请输入正确的邮箱'))
				}
			}, 100)
		};
		//校验验证码
		var code1 = (rule, value, callback) => {
			if (value == "") {
				callback(new Error("请输入验证码"));
			} else {
				callback();
			}
		};
		//校验密码
		var validatePass = (rule, value, callback) => {
			if (value == "") {
				callback(new Error("请输入密码"));
			} else if (value.length < 6) {
				callback(new Error("请输入6位以上的密码（包含6位）"));
			} else {
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请再次输入密码"));
			} else if (value !== this.form.password) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		};
		return {
			loading: false,
			getCodeDisabled: false,
			type: '/login', //确定在登录注册忘记的页面
			timer: null,
			getCodeText: "获取验证码",
			getCodeDisabled1: false,

			form: {
				phone: "",
				email: "",
				code: "",
				password: "",
				rePassword: "",
				tenantId: '000000',
				captchaVerification: ""
			},
			rules: {
				phone: [
					{
						required: true,
						trigger: "blur",
						validator: userName2,
					}
				],
				email: [
					{
						required: true,
						trigger: "blur",
						validator: email2,
					}
				],
				code: [
					{
						required: true,
						trigger: "blur",
						validator: code1,
					}
				],
				password: [
					{
						required: true,
						trigger: "blur",
						validator: validatePass,
					}
				],

				rePassword: [
					{
						required: true,
						trigger: "blur",
						validator: validatePass2,
					}
				],
			}, //校验规则
		}
	},
	mounted() {
		// 获取 localStorage 中的记住密码状态
		const rememberMe = localStorage.getItem('rememberMe');
		if (rememberMe) {
			this.form.rememberMe = JSON.parse(rememberMe);
		}
		// 如果记住密码，则从 localStorage 中获取用户名和密码
		if (this.form.rememberMe) {
			this.form.rememberMe = localStorage.getItem('username');
			this.form.rememberMe = localStorage.getItem('password');
		}
	},
	methods: {
		goHome() {
			this.$router.push({
				name: 'index'
			})
			localStorage.setItem('path', '/')
		},
		getType(type) {
			this.type = type
		},
		getCode(form, type) {

			let _this = this;
			const mailReg = /^1(3[0-9]|4[579]|5[0-35-9]|6[56]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
			if (this.form.phone == "") {
				this.$message.error({
					showClose: true,
					duration: 1500,
					message: "请先输入手机号！",
				});
				return false
			}
			if (!mailReg.test(this.form.phone)) {
				this.$message.error({
					showClose: true,
					duration: 1500,
					message: "手机号格式不正确！",
				});
				return false
			}

			this.$refs.verify.show()
		},
		// 滑块校验通过
		success(e) {
			let _this = this;
			this.form.captchaVerification = e.captchaVerification

			this.loading = true
			this.form.type = this.type.slice(1)

			getRegisterCode(this.form).then((res => {
				if (res.code == 200) {
					_this.$message.success({
						showClose: true,
						duration: 3000,
						message: "发送成功，请注意查收",
					});
					this.loading = false
					if (!_this.timer) {
						_this.getCodeText = TIME_COUNT;
						_this.getCodeDisabled = true;
						_this.timer = setInterval(() => {
							if (_this.getCodeText > 0 && _this.getCodeText <= TIME_COUNT) {
								_this.getCodeText--;
							} else {
								_this.getCodeDisabled = false;
								clearInterval(_this.timer);
								_this.getCodeText = "获取验证码";
								_this.timer = null;
							}
						}, 1000);
					}
				} else {
					_this.$message.error({
						showClose: true,
						duration: 1500,
						message: res.msg || '请求失败'
					});
				}
			})).finally(() => {
				this.loading = false
			})
		},
		// 注册
		toRegister(form) {
			let _this = this;
			// console.log(this.form);
			this.$refs[form].validate((valid) => {
				if (valid) {
					this.form.type = this.type.slice(1)
					this.loading = true

					console.log(this.type);
					

					getRegister1(_this.form).then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.loading = false
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg,
								type: "success",
								onClose: function () {
									_this.type = "/login";
									_this.form.phone = "";
									_this.form.email = "";
									_this.form.code = "";
									_this.form.password = "";
									_this.form.rePassword = "";
								},
							});

						} else {
							this.loading = false
							_this.$message.error({
								showClose: true,
								duration: 1500,
								message: res.msg,
								type: "error",
							});
						}
					}).catch(err => {
						this.loading = false
						_this.$message.error({
							showClose: true,
							duration: 1500,
							message: err.response.data.msg || '请求失败'
						});
					})
				}
			});
		},
		// 重置密码
		getResetPassword(form) {
			let _this = this;
			this.$refs[form].validate((valid) => {
				if (valid) {
					console.log(_this.form);
					this.loading = true
					this.form.type = this.type.slice(1)

					getForget(_this.form).then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.loading = false
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg,
								type: "success",
								onClose: function () {
									_this.type = "/login";
									_this.form.phone = "";
									_this.form.code = "";
									_this.form.password = "";
									_this.form.rePassword = "";
								},
							});

						} else {
							this.loading = false
							_this.$message.error({
								showClose: true,
								duration: 1500,
								message: res.msg,
								type: "error",
							});
						}
					}).catch(err => {
						this.loading = false
						_this.$message.error({
							showClose: true,
							duration: 1500,
							message: err.response.data.msg || '请求失败'
						});
					})
				}
			});
		},
		login() {
			this.type = '/login'
		},

		//去忘记密码页
		forget() {
			this.type = '/forget'
			this.$nextTick(() => {
				this.$refs.form.resetFields();
			})
		},
		//去注册页
		register() {
			this.type = '/register'
			this.$nextTick(() => {
				this.$refs.form.resetFields();
			})
		},

	}
}
</script>
<style scoped lang="scss">
.go_login {
	text-align: right;
	color: #888888;
	transition: all .2s;

	&:hover {
		cursor: pointer;
		color: #545454;
	}
}

.login_bottom {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;

	.login_bottom_btn_ {
		color: #888888;

		transition: all .2s;

		&:hover {
			cursor: pointer;
			color: #545454;
		}
	}

	.register1,
	.register2 {
		margin-left: 10px;
	}
}

.box {
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	height: 100vh;
	margin: 0 auto;
	background: url('@/assets/image/login-bj.jpg');
	// background: linear-gradient(to top, #ffffff, #f0f0f0);
	background-size: cover;
	/* 让背景图片铺满整个元素 */
	background-position: center;
	/* 图片居中对齐 */
	background-repeat: no-repeat;
	box-sizing: border-box;
	/* 	-webkit-animation: animate-cloud 20s linear infinite;
	animation: animate-cloud 20s linear infinite; */
}

@keyframes animate-cloud {
	from {
		background-position: 0 bottom;
	}

	to {
		background-position: -500px bottom;
		/* 根据需要调整移动距离 */
	}
}

.main {
	position: relative;
	width: 600px;
	// min-height: 500px;
	margin: 0 auto;
	display: flex;
	background-color: #fff;
	// min-height: 100px;
	border-radius: 20px;
	// overflow: hidden;
	box-sizing: border-box;

	.go_home {
		position: absolute;
		top: -40px;
		left: 10px;
		font-size: 15px;
		color: #888888;
		transition: all .2s;

		&:hover {
			cursor: pointer;
			color: #545454;
		}
	}

	.right {
		width: 100%;
		display: flex;
		flex-direction: column;
		// align-items: center;
		justify-content: space-around;

		.title {
			text-align: center;
			font-size: 32px;
			font-weight: bold;
			color: #3470ff;
			padding-top: 50px;
		}

		.login_box {
			width: 100%;
			padding: 20px 100px;
			box-sizing: border-box;

			.form {
				width: 100%;
				margin: 0 auto;
			}

			.forget {
				text-align: end;
			}

			.el-button {
				height: 48px;
				font-size: 16px;
			}
		}
	}
}
</style>
<style>
.el-input input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 400px rgba(255, 255, 255, 1) inset;

}
</style>
