<template>
    <el-card class="hot_box" body-style="padding:0; border:none">
        <div class="hot_title">
            {{ activeIndex == '0' ? '干货推荐' : '热门资讯' }}
        </div>
        <div class="hot_item" v-for="(item, index) in tableData" :key="index">
            <div class="hot_icon" :style="{ color: getBackgroundColor(index) }">
                <div class="index">
                    {{ index + 1 }}
                </div>
                <span class="iconfont icon-paimingbiaoqian" :style="{ color: getBackgroundColor(index) }"></span>
            </div>
            <div class="hot_content" @click="onTitleClick(item.redirectUrl)">
                {{ item.articleTitle }}
            </div>
        </div>

    </el-card>

</template>

<script>
import { getArticleByCategory } from "@/api/trade/index"

export default {
    data() {
        return {
            paramap: {
                size: 10
            },
            tableData: [],
        }
    },
    props: {
        activeIndex: {
            type: Number,
            default: 0
        }
    },
    computed: {
        currentIndex() {
            return this.activeIndex;
        }
    },
    watch: {
        currentIndex(val) {
            this.paramap.current = 1
            this.getDataList()
        }
    },
    methods: {
        getBackgroundColor(index) {
            if (index === 0) {
                return '#C7000B'; // 第一个元素背景颜色为红色
            } else if (index === 1) {
                return '#FF6A00'; // 第二个元素背景颜色为绿色
            } else if (index === 2) {
                return '#FFC300'; // 第三个元素背景颜色为蓝色
            } else {
                return '#000'; // 其他元素透明背景
            }
        },
        getDataList() {
            getArticleByCategory({ type: this.activeIndex }).then(res => {
                this.tableData = res.data
                console.log(this.tableData);

            })
        },
        onTitleClick(url) {
            window.open(url, '_blank')
        }
    },
    mounted() {
        this.getDataList()
    }
}
</script>
<style scoped lang="scss">
.hot_box {
    width: 317px;
    height: 100%;
    // border: 1px solid #000;
    display: flex;
    flex-direction: column;
    border: none;
    background: linear-gradient(to top, #ffffff, #F2F4F9, #F2F4F9);

    .hot_title {
        text-align: center;
        margin: 0 20px;
        padding: 20px;
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid #e5e5e5;

    }

    .hot_item {
        display: flex;
        padding: 10px;
        align-items: center;

        .hot_icon {
            font-size: 14px;
            margin-right: 10px;
            position: relative;
            // width: 32px;

            .index {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;
                color: #ffffff;
                display: flex;
                align-items: baseline;
                justify-content: center;

            }

            span {
                top: 0;
                left: 0;
                color: #000;
                font-size: 30px;
            }
        }

        .hot_content {
            font-size: 14px;
            white-space: nowrap;
            /* 禁止文字换行 */
            overflow: hidden;
            /* 超出部分隐藏 */
            text-overflow: ellipsis;
            transition: all .2s;

            &:hover {
                color: #3470ff;
                cursor: pointer;
            }

        }
    }
}
</style>