import request from '@/utils/request';

// 推荐文章分页查询  articleCategory  0. 箭弩学术, 1. 高被引
export const getArticleList = (current, size, params) => {
    return request({
        url: '/wxarticle/list',
        method: 'get',
        params: {
            ...params,
            current,
            size
        }
    })
}

// 根据文章类型获取推荐文章数据 type 0. 箭弩学术, 1. 高被引 ，limit 条数 默认10
export const getArticleByCategory = (params) => {
    return request({
        url: '/wxarticle/recommend',
        method: 'get',
        params
    })
}