<template>
    <div class="news_box">
        <div class="news_item" v-for="(item, index) in records" :key="index">
            <div class="title" @click="onTitleClick(item.redirectUrl)">
                {{ item.articleTitle }}
            </div>
            <div class="content">
                {{ item.articleAbstract }}
            </div>
            <div class="time">
                <span style="margin-right: 10px;">{{ '来源 : ' + (activeIndex == '0' ? '箭弩学术 ' : '高被引 ') }}</span>
                <span>{{ (item.submitTime).replace(/T/g, ' ') }}</span>
            </div>
        </div>
        <div class="pagination" :class="lastNone ? 'lastNone' : 'lastBlock'">
            <el-pagination background layout="prev, pager, next, sizes,jumper" @current-change="handleCurrentChange"
                @size-change="handleSizeChange" :current-page="paramap.current" :page-size="paramap.size - 0"
                :page-sizes="[10, 20, 30, 40]" :total="paramap.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getArticleList } from "@/api/trade/index"

export default {
    data() {
        return {
            lastNone: '',
            paramap: {
                current: 1,
                size: 10,
                total: null
            },
            records: [],
        }
    },
    props: {
        activeIndex: {
            type: Number,
            default: 0
        }
    },
    methods: {
        //更改页码
        handleCurrentChange(currentPage) {
            this.paramap.current = currentPage;
            this.getDataList()
        },
        handleSizeChange(val) {
            this.paramap.size = val
            this.getDataList()
        },
        //分页隐藏最后一个
        lastLi() {
            this.$nextTick(_ => {
                this.lastNone = !!document.getElementsByClassName('btn-quicknext')[0]
            })
        },
        async getDataList() {
            await getArticleList(this.paramap.current, this.paramap.size, { articleCategory: this.activeIndex }).then(res => {
                this.records = res.data.records
                this.paramap.total = res.data.total
            })
        },
        onTitleClick(url) {
            window.open(url.replace(/&amp;/g, '&'), '_blank')
        }
    },
    computed: {
        currentIndex() {
            return this.activeIndex;
        }
    },
    watch: {
        currentIndex(val) {
            this.paramap.current = 1
            this.getDataList()
        }
    },
    mounted() {
        this.getDataList()
    }
}
</script>
<style scoped lang="scss">
.news_box {
    display: flex;
    flex-direction: column;
    width: 814px;

    .news_item {
        border-bottom: 1px solid #e5e5e5;
        padding: 20px 0;
    }

    .title {
        font-size: 18px;
        white-space: nowrap;
        /* 禁止文字换行 */
        overflow: hidden;
        /* 超出部分隐藏 */
        text-overflow: ellipsis;
        /* 显示省略号 */
        transition: all .2s;

        &:hover {
            color: #3470ff;
            cursor: pointer;
        }
    }

    .content {
        font-size: 14px;
        color: #666;
        margin: 24px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;

    }

    .time {
        text-align: end;
        font-size: 14px;
        color: #666;
    }

    // 分页
    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    .lastNone {
        ::v-deep .el-pagination {
            .el-pager {
                li:nth-last-child(1) {
                    display: none;
                }
            }
        }
    }

    .lastBlock {
        ::v-deep .el-pagination {
            .el-pager {
                li:nth-last-child(1) {
                    display: inlibe-block;
                }
            }
        }
    }
}

.hot_box {
    width: 317px;
    height: 100%;
    // border: 1px solid #000;
    display: flex;
    flex-direction: column;
    border: none;
    background: linear-gradient(to top, #ffffff, #F2F4F9, #F2F4F9);

    .hot_title {
        text-align: center;
        margin: 0 20px;
        padding: 20px;
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid #e5e5e5;

    }

    .hot_item {
        display: flex;
        padding: 20px;
        align-items: center;

        .hot_icon {
            font-size: 14px;
            margin-right: 10px;
        }

        .hot_content {
            font-size: 14px;
            white-space: nowrap;
            /* 禁止文字换行 */
            overflow: hidden;
            /* 超出部分隐藏 */
            text-overflow: ellipsis;

        }
    }
}
</style>