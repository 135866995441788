<template>
	<div class="header">
		<div class="header_bottom" v-sticky-top="{ topOffset: 0 }">
			<div class="bottom_box">
				<div class="header_left">
					<div class="logo pointer">
						<img :src="require('@/assets/image/logo.png')" alt="" @click="getToMenu('/')">
					</div>

				</div>
				<div class="menuList">
					<div class="menu_item">
						<div class="title" :class="{ active: headerActive == '/' }" @click="getToMenu('/')">
							首页
						</div>
					</div>
					<div class="menu_item">
						<div class="title" :class="{ active: headerActive == '/appraisal' }"
							@click="getToMenu('/appraisal')">
							论文差距测评
						</div>
					</div>
					<div class="menu_item">
						<el-dropdown @command="handleToHeadline">
							<div class="title" :class="{ active: headerActive == '/promotion' }">成果推广 <i
									class="el-icon-arrow-down"></i></div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="/paperPromotion">论文影响力推广</el-dropdown-item>
								<el-dropdown-item command="/personalPromotion">个人影响力推广</el-dropdown-item>
								<el-dropdown-item command="/journalPromotion">期刊影响力推广</el-dropdown-item>
								<el-dropdown-item command="/consult">高校学科建设咨询</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="menu_item">
						<el-dropdown @command="reportToHeadline">
							<div class="title" :class="{ active: headerActive == '/report' }">报告订阅 <i
									class="el-icon-arrow-down"></i></div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="/paperReport">论文引文追踪报告</el-dropdown-item>
								<el-dropdown-item command="/prove">代开检索&ESI高被引证明</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="menu_item">
						<el-dropdown @command="tradeToService">
							<div class="title" :class="{ active: isActive() }" @click="getToMenu('/trade?page=0')">
								干货与资讯 <i class="el-icon-arrow-down"></i></div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="/trade?page=0">行业干货</el-dropdown-item>
								<el-dropdown-item command="/trade?page=1">行业资讯</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="menu_item">
						<div class="title" :class="{ active: headerActive == '/cooperation' }"
							@click="getToMenu('/cooperation')">
							商务合作
						</div>
					</div>
					<div class="menu_item">
						<div class="title" :class="{ active: headerActive == '/about' }" @click="getToMenu('/about')">
							关于我们
						</div>
					</div>

				</div>
				<div class="btn_box">
					<div>
						<el-button type="primary" style="background-color: #3470ff; border: #3470ff;">会员中心</el-button>
					</div>
					<div>
						<el-button type="primary" plain style="color: #3470ff; background-color: #fff;" @click="login"
							v-if="!this.isLogin">免费注册 / 登录</el-button>

						<el-dropdown :show-timeout="70" :hide-timeout="50" @command="onHandleCommandClick" v-else>
							<span class="user-avatar">
								<img :src="users.avatar" />
							</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item divided command="logOut">退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getLogout } from '@/api/login/login'
import { mapState, mapActions } from 'vuex';

export default {
	computed: {
		...mapState(['headerActive'])
	},
	directives: {
		'sticky-top': {
			inserted: function (el, binding) {
				const topOffset = binding.value.topOffset || 0; // 获取传入的topOffset参数，默认为0
				const stickyClass = 'sticky';
				const stickyTop = el.offsetTop - topOffset;
				const scrollHandler = function () {
					if (window.pageYOffset > stickyTop) {
						el.classList.add(stickyClass);
					} else {
						el.classList.remove(stickyClass);
					}
				};

				window.addEventListener('scroll', scrollHandler);
			}
		}
	},
	name: 'influenceHeader',
	data() {
		return {
			circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
			activeIndex: '1',
			//是否登录
			isLogin: this.$store.getters.getIsLogin,
			userInfo: this.$store.getters.getTokenInfo,
			users: null,
			// headerActive: '',
		}
	},

	mounted() {

		const users = localStorage.getItem('userInfo');
		if (users) {
			this.users = JSON.parse(users)
		}

		const path = localStorage.getItem('path')
		if (path) {
			this.updateSharedData(path)
		} else {
			this.updateSharedData('/')
		}
	},
	watch: {
		// 监听路由变化
		"$route.path": {
			handler(routePath) {
				this.initMenuActive(routePath);
			},
			immediate: true,
		},
		//监听登录状态
		"$store.getters.getIsLogin": {
			handler(isLogin) {
				this.isLogin = isLogin
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions(['updateSharedData', 'updateTradeData']),
		isActive() {
			// 判断多个条件
			return this.headerActive === '/trade?page=0' ||
				this.headerActive === '/trade?page=1' ||
				this.headerActive === '/trade?page=2';
		},
		onHandleCommandClick(path) {
			if (path === 'logOut') {
				this.loginOut()
			}
		},
		getToMenu(index, data) {
			// 路径存store
			this.updateSharedData(index)
			this.updateTradeData('2')
			localStorage.setItem('path', index);
			if (data) {
				this.handleSelect(data)
			} else {
				this.handleSelect(index)
			}
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'  // 可选，实现平滑滚动
			});
		},

		handleToHeadline(command) {
			this.getToMenu('/promotion', command)
		},

		tradeToService(command) {
			this.getToMenu(command)

		},
		reportToHeadline(command) {
			this.getToMenu('/report', command)
		},

		initMenuActive(routePath) {
			this.activeIndex = routePath;

			if (routePath == '/') {
				this.activeIndex = '/index'
			}
		},
		//退出登录
		loginOut() {
			getLogout()
				.then(res => {
					this.$store.commit('setTokenInfo', "")
					this.$store.commit('setUserInfo', "")
					this.$store.commit('setIsLogin', "")
					this.$message.success({
						showClose: true,
						duration: 1500,
						message: "成功退出",
					});

				})
				.catch(function () { });
		},
		// 导航栏跳转
		handleSelect(index) {
			// 获取当前路由的路径
			const currentPath = this.$route.fullPath;

			// 如果当前路由路径不等于目标路由路径，则进行路由跳转
			if (currentPath !== index) {
				this.$router.push(index);

			} else {
				// 当前路由路径与目标路由路径相同，无需重复导航
				console.log('路由重复');
			}
		},
		//登录
		login() {
			this.$router.push('/login')
		},

	}
}
</script>
<style scoped lang="scss">
.header {
	width: 100%;
	display: flex;
	flex-direction: column;
}


.header_bottom {

	background-color: #fff;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;

	.bottom_box {
		width: 100%;
		display: flex;
		align-items: center;
		// justify-content: center;
		justify-content: space-around;
		box-sizing: border-box;

		.header_left {
			display: flex;
			align-items: center;
			// width: 1200px;
		}
	}

	.logo {
		img {
			width: 120px;
		}
	}
}

.sticky {
	position: fixed;
	top: 0;
	// left: 50%;
	// transform: translate(-50%);
	// width: 100%;
	z-index: 999;
	background-color: #fff;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
}

::v-deep .login_box .el-button {
	background-color: #18305B;
	border: #18305B;
	color: #fff;

}

.el-menu--horizontal {
	.el-menu-item {
		border-bottom: none !important; //去掉导航下划线
		border-radius: 30px;
		font-size: 20px;
		padding: 0 30px;
	}

	.el-menu-item:hover {
		color: #000 !important;
		/* 设置你想要的背景颜色 */
	}
}



::v-deep .menu .el-menu-demo .is-active .el-submenu__title {
	background-color: #1158E8;
	color: #fff;
}

::v-deep .menu .el-submenu__title {
	border-bottom: none !important; //去掉导航下划线
	border-radius: 30px;
	font-size: 20px;
	height: 40px !important;
	line-height: 40px !important;
}

::v-deep .el-menu.el-menu--horizontal {
	border: none;
}


// 导航栏
.menuList {
	// width: 1200px;
	// margin: 0 auto;
	display: flex;
	// flex-grow: 1;
	// padding: 0 50px;
	// font-size: 18px;
	width: 1200px;

	.menu_item {
		margin: 0 10px;

		.title {
			padding: 10px;
			// background-color: #0894CE;
			border-radius: 20px;
			color: #000;
			font-size: 16px;
			// font-weight: bold;
			transition: all 0.3s;
			cursor: pointer;

		}

		.active {

			color: #3470ff;
		}

		.show_box {
			display: none;
		}
	}

	.menu_item .title:hover {
		// background-color: #0894CE;
		color: #000;
	}

	.dropdown:hover {
		background-color: #0894CE;
		color: #fff;
	}
}

.login_box {
	/* display: flex;
	align-items: center; */
	font-size: 14px;
	cursor: pointer;
	height: 100%;
}

.isLogin {
	position: relative;
}

.user_hover {
	position: absolute;
	left: 0;
	padding: 10px;
	background-color: #fff;
	z-index: 999;
	color: #000;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	box-sizing: border-box;
	white-space: nowrap;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease, transform 0.3s ease;

	·:nth-child(1) {
		border-bottom: 1px solid #000;

	}

	.hover_item>div {
		padding: 10px;
		font-size: 16px;
	}
}


.hover_item:hover {
	background-color: #e7e7e7;

	/* 鼠标移入时背景颜色改变 */
}

.isLogin:hover .user_hover {
	opacity: 1;
	pointer-events: auto;
}

//  按钮
.btn_box {
	display: flex;
	justify-content: space-between;
	align-items: center;

	>div {
		button {
			padding: 10px;
			margin: 0 10px;
		}
	}

}
</style>
<style>
.el-menu--popup {
	z-index: 100;
	min-width: 105px;
	border: none;
	padding: 5px;
	border-radius: var(--el-border-radius-small);
	-webkit-box-shadow: var(--el-box-shadow-light);
	box-shadow: var(--el-box-shadow-light);
}

.user-avatar {
	margin-left: 10px;
}

.user-avatar img {
	width: 40px;
	height: 40px;
}
</style>