<template>
    <div class="privacy">
        <div class="n_privacy">
            <h2 style="text-align: center;">隐私协议</h2>
            <br>
            <div>公布日期：2024年8月1日</div>
            <br>
            <span class="ti2">欢迎访问highlycitedpaper.com！我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全可控。本隐私政策包含了我们收集、使用、共享、存储和保护您的个人信息的条款，我们希望通过本隐私政策向您清晰地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读本隐私权政策，以便您更好地做出适当的选择。</span>

            <div class="storng m20">一、适用范围</div>
            <span class="ti2">“高被引”或“我们”）就[www.highlycitedpaper.com]网页（简称“本网站”）的使用采取本隐私政策，</span><span
                class="storng">但本隐私政策并不涵盖拥有独立隐私政策的关联网站。</span>本隐私政策适用于通过本网站收集或由用户直接提供的个人信息，<span
                class="storng">但并不涵盖任何通过其他网站（包括我们关联公司的网站）收集到的个人信息或非个人信息。如您使用高被引或其关联公司提供的产品或服务，并欲了解相关个人信息如何被处理，请查阅该产品或服务相应的隐私政策。</span>

            <div class="storng m20">二、信息的收集及使用</div>
            <span class="ti2">在您使用本网站时，我们可能需要收集和使用的您的个人信息包括：</span>
            <br>
            <span class="ti2">1. 您向我们提供的信息。当您在本网站的“投资者关系”或“新闻及媒体资源”页面向我们提交问询、订阅我们最新的公开信息的分享、申请年报纸质版副本或使用本隐私政策中指定的联系方式联系我们时，您填写及/或提交的特定信息，<span class="storng">包括姓名、电话号码、传真、电子邮箱、地址、单位、用户类型、邮政编码、城市、国家、问询类别、问询详情、服务类别等。这些信息只会用于能够联系上您及处理、解答您的问题，或向您提供您订阅的服务，或按照您的申请向您邮寄我们的年报纸质版副本，不会用于其他用途。</span></span>
            <span class="ti2">2. 在您使用本网站过程中收集的信息。为了优化访问体验及页面性能，进行网站使用情况的访问统计，以及预防、发现危害网络安全的行为等的需要，在您使用本网站时，我们会记录您的浏览器和设备信息，</span><span
                class="storng">包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息等。</span>

            <div class="storng m20">三、Cookie</div>
            <span class="ti2">Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用本网站时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符（以下简称“Cookie”），以收集、标识和存储您访问、使用本网站时的信息。我们承诺，不会将Cookie
                用于本隐私政策所述目的之外的任何其他用途。我们使用
                Cookie主要为了保障本网站的安全、高效运转，可以使我们确认您使用本网站的安全状态，排查崩溃、延迟的相关异常情况等。</span><span
                class="storng ti2">我们只会在您自愿的情况下收集该些信息，您有权选择是否接受Cookie。如需选择接受或拒绝Cookie，请在您首次访问本网站时点击网页底端的弹出式横幅进行选择；如果您选择拒绝安装Cookies，本网站的部分功能有可能因此无法正常运作。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。您可能因为这些修改，无法使用依赖于Cookie的服务或相应功能。</span>

            <div class="storng m20">四、信息的共享及披露</div>
            <div>
                <span class="ti2">我们不会对外共享您的个人信息，但以下情形除外：</span>
                <br>
                <span class="ti2">（1）按照您的要求提供服务或回应、处理您的问询或帮助解决您的问题的情况下，向我们的关联公司分享您的个人信息，但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。</span>
                <span class="ti2">（2）在适用的法律要求或响应法律程序的情况下，我们可能会向相关的执法机关或者其他政府机关依法披露您的个人信息。</span>
                <span class="ti2">（3）我们也可能在以下情形下披露您的个人信息：需要调查或预防实际或涉嫌非法活动或其他不当行为，或就该些活动或行为采取其他行动；或需要保护我们、我们的用户或他人的权利、财产或安全等。</span>
                <span class="ti2">（4）取得您同意的情况下共享。</span>
            </div>

            <div class="storng m20">五、您的权利</div>
            <span class="ti2">您拥有我们持有的有关您的个人信息在适用的法律规定范围内的相关法定权利。如您需要行使您的任何权利，请通过</span><span
                class="extrude"> service@highlycitedpaper.com </span><span>与我们联系，我们会在符合适用的法律法规要求的情形下响应您的请求。</span>

            <div class="storng m20">六、信息存储及安全保护措施</div>
            <span class="storng ti2">我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求。</span>
            <span>我们使用阿里云为本网站提供信息存储服务，您的个人信息由我们位于中华人民共和国境内的服务器处理。</span><br>
            <span class="ti2">我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受SSL协议加密保护，我们提供HTTPS协议安全浏览方式；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。
            </span>

            <div class="storng m20">七、第三方链接及相关政策</div>
            <span class="ti2">
                本网站可能含有其他网站的链接，而该些网站可能由第三方及／或其关联机构拥有或运营，该些第三方及／或其关联机构或会收集您的个人信息，而他们以及他们的服务器可能位于中华人民共和国、美国或其他国家/地区。我们无法控制亦不负责任何该些第三方及其关联机构的隐私政策及数据保护政策。我们强烈建议您查阅所有该些第三方及其关联机构的隐私政策及数据保护政策，以了解他们如何处理有可能向您收集的个人信息。</span>

            <div class="storng m20">八、政策更新</div>
            <span class="ti2">本网站的隐私政策可能会定期更新，而最后更新日期会在本页标明。我们强烈建议您不时访问本页面以查看隐私政策是否有任何更新或变更。</span><span
                class="storng">若您继续使用本网站，即表示您接受我们的隐私政策的更新内容。</span>

            <div class="storng m20">九、未成年人保护</div>
            <span class="ti2">本网站服务主要面向达到法定成年年龄的用户提供。</span><span
                class="storng">如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。</span>

            <div class="storng m20">十、联系我们</div>
            <span class="ti2">我们设立了个人信息保护专职部门，如您对本隐私政策内容有任何疑问、意见、建议，您可通过</span><span
                class="extrude"> service@highlycitedpaper.com </span><span>与我们联系。</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'privacy',
    data() {
        return {}
    },
    created() {

    },
    methods: {}
}
</script>
<style lang='scss'>
.privacy {
    line-height: 2.4;
    background-color: #eee;
    padding: 40px 130px;
}

.n_privacy {
    background-color: white;
    padding: 48px 100px;
    border-radius: 10px;
}

.storng {
    font-weight: 600;
}

.extrude {
    color: blue
}

.m20 {
    margin: 20px 0 6px 0;

}

.ti2 {
    text-indent: 2em;
    display: inline-block;
}
</style>