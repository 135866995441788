<template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        论文影响力<span>推广</span>
                    </div>
                    <div class="center">
                        通过大数据分析，把您的论文精准推送给大量可能引用的研究者，从而提升
                        您的个人学术数据，提升学术影响力。
                    </div>
                    <div class="btn">
                        <el-button class="top_btn" @click="openRandomDialog">开始推广</el-button>
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/journal_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <div class="section">
                <div class="left">
                    <div class="title">
                        高被引论文
                    </div>
                    <div class="center">
                        高被引论文是指最近十年内的文章，在同年同领域被引次数前1%的论文。
                    </div>
                    <div class="icon">
                        <span class="iconfont icon-24gf-tags "></span> 遴选标准
                    </div>
                    <div class="bottom">
                        论文必须为SCI或者SSCI且文献类型为Article或者Review
                    </div>
                </div>
                <div class="right">
                    <div class="title">
                        热点论文
                    </div>
                    <div class="center">
                        热点论文是指最近两年内的文章，在同年同领域最近两个月被引次数前1‰的论文。
                    </div>
                    <div class="icon">
                        <span class="iconfont icon-24gf-tags "></span> 遴选标准
                    </div>
                    <div class="bottom">
                        论文必须为SCI或者SSCI且文献类型为Article或者Review
                    </div>
                </div>
            </div>
            <div class="quotation">
                <div class="title">
                    引文的重要性
                </div>
                <div class="content">
                    发表论文是进行学术交流和传播研究成果的主要途径之一。在自然科学领域，又特别是在基础研究中，论文发表后被引用的次数是衡量其影响力和价值的重要指标之一。一篇高被引论文往往能够引领学科发展，推动科技进步，甚至改变人类社会的生活方式。在自然科学领域，判断一篇论文的学术影响力，是该论文发表后被他人引用的频度，被引用的次数越多，表明原始创新力和影响力越大。
                </div>
            </div>

        </main>
        <div class="force">
            <div class="title">
                论文影响力推广
            </div>
            <div class="center">
                <p> 通过大数据分析，把您的论文精准推送给大量可能引用的研究者，从而提升</p>
                <p>
                    您的个人学术数据，提升学术影响力。
                </p>
            </div>
            <div class="btn">
                <el-button class="force_btn" @click="() => dialogVisible0 = true">开始推广</el-button>
            </div>
        </div>
        <journal-recommend></journal-recommend>
        <dialogQR :dialogVisible="dialogVisible0" :path="$route.path" :index="2" @cancel="() => dialogVisible0 = false"
            @confirm="() => dialogVisible0 = false">
        </dialogQR>
        <dialogQR :dialogVisible="dialogVisible" :path="$route.path" :index="1" @cancel="() => dialogVisible = false"
            @confirm="() => dialogVisible = false">
        </dialogQR>
    </div>
</template>
<script>
import journalRecommend from "@/components/common/journalRecommend.vue"
import dialogQR from "@/components/common/dialogQR.vue";

export default {
    components: {
        journalRecommend,
        dialogQR
    },
    data() {
        return {
            dialogVisible0: false,
            dialogVisible: false,
            activeName: 'first'
        }
    },
    created() {

    },
    mounted() {


    },
    methods: {
        openRandomDialog() {
            this.dialogVisible = true
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #3470FF;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 487px;
        height: 431px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;
            color: #c5d3f8;

            span {
                color: #fcfba0;
            }
        }

        .center {
            width: 524px;
            padding: 20px 0;
        }

        .top_btn {
            // background-color: #3470FF;
            // border: #3470FF;
            width: 156px;
            height: 56px;
        }
    }
}

main {
    width: 1200px;
    // margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .section {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;

        >div {
            width: calc(100% / 2);
            padding: 20px 20px 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

        }

        .left {
            color: #fff;
            background-color: #434343;
            border-bottom: 5px solid #979797;
            border-top: 5px solid #434343;
        }

        .right {
            color: #c5d3f8;
            background-color: #43439C;
            border-top: 5px solid #3470ff;
            border-bottom: 5px solid #43439C;
        }

        .title {
            font-size: 30px;
            font-weight: bold;
            padding: 20px;
        }

        .center {
            padding: 20px;
        }

        .icon {
            padding: 20px 0;
        }

        .bottom {
            padding: 20px;
        }
    }

    .quotation {
        padding: 20px;
        background-color: #434343;
        margin: 50px 0;
        line-height: 30px;

        .title {
            font-size: 30px;
            font-weight: bold;
            padding: 20px;
            color: #fff;
        }

        .content {
            color: #979797;
            padding: 20px;
        }
    }



}

.force {
    width: 100%;
    background-color: #434390;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin-bottom: 50px;
    box-sizing: border-box;

    .title {
        font-size: 30px;
        font-weight: bold;
        padding: 20px;
        color: #c5d3f8;
    }

    .center {
        font-size: 16px;
        color: #c5d3f8;
        padding: 20px;
        text-align: center;
    }

    .btn {
        padding: 20px;
    }

    .force_btn {
        width: 156px;
        height: 42px;
    }
}
</style>
<style scoped></style>