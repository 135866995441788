<template>
    <div class="user_agreement">
        <div class="n_user_agreement">
            <h2 style="text-align: center;">高被引用户使用协议</h2>
            <br>
            <div class="storng m20">一、特别提示</div>
            <div>
                1.本《高被引用户使用协议》（以下简称“本协议”）是您（以下简称“您”或“用户”）与高被引网站(网址:<a href="www.highlycitedpaper.com"
                    target="_blank">www.highlycitedpaper.com</a>)
                之间关于您注册并使用高被引以获得相应服务所订立的对双方有约束力的协议。
            </div>
            <div>
                2.高被引已经发布的或未来可能发布的各项服务对应的服务协议、使用规则、算法规则、法律声明等，均构成本协议的组成部分，与本协议具有同等法律效力。如果本协议某一条款被认定为无效，而其他条款仍能保持有效且其执行不受影响，高被引可决定是否继续履行该等其他条款。如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属或所处国家或地区的法律。
            </div>
            <div>
                3.请您认真阅读本协议并充分理解本协议各条款的内容。如果您对本协议的条款有疑问的，请通过高被引网站所载的联系方式进行咨询，高被引将向您解释条款内容。一旦您按照注册页面提示填写信息、提交文件、阅读并同意本协议且完成注册后，即表示您已经充分阅读、理解并接受本协议的全部内容。如果您不同意接受本协议或其任何条款的约定或无法准确理解高被引对本协议条款的解释，您应当立即终止注册程序及在高被引网站上的任何操作。
            </div>
            <div>
                4.您理解并同意，高被引有权根据法律法规的变化及经营需要，不时对本协议条款进行修改并公布在高被引网站，您应在确认充分理解并同意更新后的本协议后方使用高被引服务。一旦您开始使用高被引服务，将被视为对本声明和政策内容的接受和认可。
            </div>
            <div>
                5.您确认，您是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下简称“法律主体”）。如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用高被引，并特别注意未成年人使用条款。如果您不具备前述法律主体资格，请立即终止注册程序及在高被引网站上的任何操作，且高被引有权注销您的帐号。高被引因此受到损失的，将向您和/或您的监护人索赔。
            </div>
            <div class="storng m20">二、帐号管理</div>
            <div>1.帐号的获取</div>
            <div>
                在您按照高被引网站注册页面的提示信息填写完整注册信息、完成《高被引用户使用协议》的阅读、并完成帐号激活操作等全部高被引注册程序后，或者高被引以其他方式向您提供高被引登录帐号后，您即获得了高被引帐号并正式成为高被引的用户。
            </div>
            <div>2.注册信息</div>
            <div>
                您应当保证您在注册过程中填写的信息准确、真实和完整。如果您填写的信息（包括但不限于联系地址、电话、电子邮件等）有任何变更，您应当及时登录高被引进行更新。如果高被引发现您填写的注册信息存在错误、不真实或不完整的情况，或通过您填写的联系方式信息无法联系到您，高被引有权要求您立即进行改正、更新相关信息。
            </div>
            <div>3.实名认证</div>
            <div>
                您理解并同意，即使您获得了高被引帐号亦不代表您已开通了高被引的所有服务。为保障帐号及交易安全，高被引可能会随时要求您提交更多的身份资料和信息以完成帐号的实名认证，只有完成帐号的实名验证，您才可以使用相应的高被引服务。您授权高被引可以通过第三方查询和审核您的身份和资料。
            </div>
            <div>4.帐号安全</div>
            <div>
                您应当妥善保管您的高被引帐号和密码，您通过高被引帐号执行的一切操作都不可撤回并将被视为是您身份及行为的唯一识别依据，您须对在该高被引帐号下发生的所有活动承担责任。请您注意保护高被引帐号及密码安全，并在使用完毕高被引服务后安全退出登录。除非依照法律规定或者经过高被引的同意，您不得以任何方式向任何地第三方泄露、转让或赠与您的高被引帐号，否则高被引有权随时中止或终止您的帐号使用，甚至注销您的帐号。一旦您发现任何人未经您的授权使用您的高被引帐号和密码，您应当立即通知高被引，高被引会在合理时间内采取适当措施，但对于在高被引采取措施之前发生的损失，高被引不承担责任。
            </div>
            <div>5.帐号查询、冻结及注销</div>
            <div>您理解并同意，高被引根据合理判断，有权在下列情形下自行对您的帐号进行查询（包括但不限于查询注册信息、交易信息、资金信息等）、冻结和/或注销：</div>
            <div>1） 国家司法、行政、安全机关等权力机构要求的；</div>
            <div>2） 您通过高被引帐号的操作和交易存在违反本协议约定、法律法规和/或侵犯第三人合法权益的情形的；</div>
            <div>3） 高被引基于单方面的合理判断，认为您的帐号操作、交易记录等存在异常的；</div>
            <div>4） 您的帐号遭到其他用户投诉，且投诉用户提供一定证据予以证明的；</div>
            <div>5） 您连续6个自然月未使用您的帐号登录高被引网站，且您的帐号中不存在未到期的高被引服务的；</div>
            <div>6） 您实施了影响或侵害高被引和/或高被引关联公司的合法权益的行为，包括但不限于：</div>
            <div>a. 违反高被引和/或高被引关联公司的服务协议、条款及规范等内容；</div>
            <div>b. 破坏或试图破坏高被引和/或高被引关联公司公平交易环境或正常交易秩序；</div>
            <div>c. 任何使用含有高被引和/或高被引关联公司的名称、品牌且有误导性的表述或标识，明示或暗示与高被引和/或高被引关联公司具有某种关系的；</div>
            <div>d. 高被引认为可能是与上述行为性质相同或存在上述类似风险的其他情形。</div>
            <div class="storng m20">三、使用规则</div>
            <div>1.您在使用高被引服务的过程中，应当遵守国家、地方法律法规、行业惯例和社会公共道德。不得利用高被引进行储存、发布、传播以下信息和内容：</div>
            <div>1） 违反国家法律法规政策的任何信息；</div>
            <div>2） 违反国家规定的政治宣传和/或新闻信息；</div>
            <div>3） 涉及国家秘密和/或安全的信息；</div>
            <div>4） 封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；</div>
            <div>5） 博彩有奖、赌博游戏；</div>
            <div>6） 违反国家民族和宗教政策的信息；</div>
            <div>7） 妨碍互联网运行安全的信息；</div>
            <div>8） 侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息。</div>
            <div>此外，您不得为他人发布和传播上述不符合国家规定和/或本协议约定的信息提供任何便利，包括但不限于设置URL、BANNER链接等。</div>
            <div>2.您保证不会利用技术或其他手段破坏或扰乱高被引秩序及网络安全。</div>
            <div>
                3.如果您使用的某项服务中包含可下载的高被引软件，则高被引仅授予您非独占性的、不可转让的、非商业性的使用许可。您不得复制、修改、发布、出售或出租服务或所含软件的任何部分，也不得进行反向工程或试图提取该软件的源代码。
            </div>
            <div>4.您应当尊重高被引及其他第三方的知识产权和其他合法权利，并保证在发生侵犯上述权益的违法事件时尽力保护高被引及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失。</div>
            <div>
                5.高被引可能会以电子邮件、手机短信、电话等方式通知您服务进展情况以及提示您进行相关操作，但高被引不保证您能够收到或者及时收到该邮件、手机短信或电话等。因此，在服务过程中您应当及时登录到高被引查看和进行相关操作。因您没有及时查看和进行相关操作而导致纠纷或损失的，高被引不承担责任。
            </div>
            <div>6.您应当遵守您与高被引签订的任何书面协议的约定，包括与高被引签署的线下合作商务协议/服务协议、本协议及构成本协议组成部分的使用规则、结算规则、法律声明等。</div>
            <div>7.如果您通过高被引使用的某项服务来自第三方的任何产品或服务，您还可能受限于该等第三方的相关条款和条件，高被引对此不予过问亦不承担任何责任，本协议不影响您与该第三方的法律关系。</div>
            <div>
                8.如果您在使用本服务过程中违反了相关法律法规或本协议约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求高被引给予协助。因此给您或者他人造成损害的，您应自行承担全部责任，高被引不承担任何责任。
            </div>
            <div>
                9.如果您违反本协议约定，高被引有权进行独立判断并采取相应措施，包括但不限于通过技术手段删除、屏蔽相关内容或断开链接等。同时，高被引有权视用户的行为性质，采取包括但不限于暂停或终止向您提供服务，限制、中止、冻结或终止您对帐号的使用，追究法律责任等措施。
            </div>
            <div>10.您违反本协议约定，导致任何主体损失的，您应当独立承担责任；高被引因此遭受损失的，您也应当一并赔偿。</div>
            <div class="storng m20">四、知识产权</div>
            <div>
                除由高被引的合作伙伴或入驻高被引的第三方服务商提供的产品或服务外，高被引上的所有内容，包括但不限于著作、图片、档案、资讯、资料、架构、数据等页面设计，均由高被引或其关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经高被引或其关联公司书面同意，您不得以任何方式或理由对上述内容进行使用、复制、修改、抄录、传播、发行或公开发表。否则，高被引有权通过法律途径依法追究您的法律责任。
            </div>
            <div class="storng m20">五、不可抗力</div>
            <div>
                遭受不可抗力的一方可中止履行本协议项下的义务直至不可抗力事件的影响消除为止，并且无需承担违约责任，但应尽合理努力克服该事件、减少损失的扩大。不可抗力指该方不能控制、不可预见或即使预见亦无法避免的事件，该等不可抗力事件足以妨碍、影响或延误任何一方根据本协议履行其全部或部分义务。该事件包括但不限于自然灾害、战争、政策变化造成的事件。
            </div>
            <div class="storng m20">六、责任限制</div>
            <div>1.您理解并同意，您在使用高被引服务及相关网络服务过程中存在的风险将完全由您本人承担，高被引在本协议约定的责任范围以外不承担任何责任。</div>
            <div>
                2.在法律允许的情况下，高被引对于与本协议有关的任何间接的、惩罚性的、特殊的、派生的损失（包括但不限于业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不承担任何责任，即使事先已被告知此等损失的可能性。
            </div>
            <div>3.您理解并确认，对于高被引向您提供的下列服务本身及其引发的任何损失，高被引无需承担任何责任：</div>
            <div>1） 高被引向您免费提供的各项网络服务；</div>
            <div>2） 高被引向您赠送或附赠的任何产品或服务。</div>
            <div>4.您理解并确认，高被引可能因下列情况无法正常提供服务，高被引就此不承担损害赔偿责任：</div>
            <div>1） 高被引在系统停机维护期间；</div>
            <div>2） 电信设备出现故障不能进行数据传输；</div>
            <div>3） 由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。</div>
            <div>
                5.您理解并同意，因您使用高被引服务、违反本协议或在您的帐号下采取的任何行动，而导致的任何第三方索赔，应且仅应由您本人承担。如果由此引起高被引及其关联公司、员工、客户、供应商和合作伙伴被第三方索赔的，您应承担全部责任。
            </div>
            <div>6.您通过高被引服务访问和使用的高被引合作伙伴或入驻高被引的第三方服务商的服务质量仅为该第三方的责任，对于该第三方的服务，高被引在任何情况下都不会承担责任。</div>
            <div class="storng m20">七、法律适用及争议解决</div>
            <div>1.本协议的成立、生效、履行、解释及纠纷解决等，均适用中国大陆法律法规。</div>
            <div>2.因本协议产生之争议，双方应首先协商解决；双方未能协商解决的，任何一方均应将争议提交本协议签订地有管辖权的人民法院裁决。双方无争议的部分，应当继续履行。</div>
            <div>3.您在使用本平台产品或者服务的过程中，如有任何疑问或者建议，可通过邮箱与我们取得联系。</div>
            <div class="m20">联系邮箱：<span class="extrude">service@highlycitedpaper.com</span></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'userAgreement',
    data() {
        return {}
    },
    created() {

    },
    methods: {}
}
</script>
<style lang='scss'>
.user_agreement {
    line-height: 2.4;
    background-color: #eee;
    padding: 40px 130px;
    line-height: 30px;
}

.n_user_agreement {
    background-color: white;
    padding: 48px 100px;
    border-radius: 10px;
}

.storng {
    font-weight: 600;
}

.extrude {
    color: blue
}

.m20 {
    margin: 20px 0 6px 0;

}

.ti2 {
    text-indent: 2em;
    display: inline-block;
}
</style>