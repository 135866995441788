import request from '@/utils/request';
import { data } from 'jquery';

export const getBanner1 = (paramMap) => {
	return request({
		url: '/joi/queryIamsetOtherData',
		method: 'post',
		data: paramMap
	})
}
/* export const getSwiper = (paramMap) => {
	return request({
		url: '/joi/randomQueryMeetingList',
		method: 'post',
		data: paramMap
	})
} */
export const getMeetingList = (paramMap) => {
	return request({
		url: '/joi/queryIamsetOtherData',
		method: 'post',
		data: paramMap
	})
}
export const getSubList = (paramMap) => {
	return request({
		url: '/joi/journalSubjectList',
		method: 'post',
		data: paramMap
	})
}

// new  
export const getSwiper = (paramMap) => {
	return request({
		url: '/url/slideshow/all',
		method: 'get',
		params: paramMap
	})
}

// 获取推荐论文列表
export const getRecommendPaperList = (params) => {
	return request({
		url: '/recommendpaper/list',
		method: 'get',
		params
	})
}